@import "npm:@rainbow-me/rainbowkit/styles.css";
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 4px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-radius: 20px;
	border: 3px solid transparent;
}

/* Hide arrows on WebKit browsers */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Hide arrows on Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

[type="text"]:focus {
	--tw-ring-color: "#5E00FF";
}

[type="time"]:focus {
	--tw-ring-color: "#5E00FF";
}

.flatpickr-day.selected {
	background: #5E00FF !important;
	border-color: #5E00FF !important;
}



/* .dark > .scrollbar-custom {
		scrollbar-color:  rgba(255, 255, 255, 0.2) transparent;
	} */

.dark .scrollbar-custom {
	scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
	--scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.dark .scrollbar-custom-surface {
	scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
	--scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.scrollbar-custom {
	scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
	--scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.scrollbar-custom-surface {
	scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
	--scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.surface-scroll {
	scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
	--scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.scroll-provenance {
	scrollbar-color: transparent transparent;
	--scrollbar-color: transparent transparent;
}

.dark .surface-scroll {
	scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
	--scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.flatpickr-calendar.noCalendar {
	width: 200px;
	border: 1px solid #5E00FF;
	outline-color: #5E00FF;
}