[data-rk] .iekbcc0 {
  box-sizing: border-box;
  text-align: left;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  line-height: normal;
}

[data-rk] .iekbcc1 {
  list-style: none;
}

[data-rk] .iekbcc2 {
  quotes: none;
}

[data-rk] .iekbcc2:before, [data-rk] .iekbcc2:after {
  content: "";
}

[data-rk] .iekbcc3 {
  border-collapse: collapse;
  border-spacing: 0;
}

[data-rk] .iekbcc4 {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

[data-rk] .iekbcc5 {
  outline: none;
}

[data-rk] .iekbcc5::-moz-placeholder {
  opacity: 1;
}

[data-rk] .iekbcc5:-ms-input-placeholder {
  opacity: 1;
}

[data-rk] .iekbcc5::-webkit-input-placeholder {
  opacity: 1;
}

[data-rk] .iekbcc5::-ms-input-placeholder {
  opacity: 1;
}

[data-rk] .iekbcc5::placeholder {
  opacity: 1;
}

[data-rk] .iekbcc6 {
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}

[data-rk] .iekbcc7:disabled {
  opacity: 1;
}

[data-rk] .iekbcc7::-ms-expand {
  display: none;
}

[data-rk] .iekbcc8::-ms-clear {
  display: none;
}

[data-rk] .iekbcc8::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[data-rk] .iekbcc9 {
  cursor: pointer;
  text-align: left;
  background: none;
}

[data-rk] .iekbcca {
  color: inherit;
  text-decoration: none;
}

[data-rk] .ju367v0 {
  align-items: flex-start;
}

[data-rk] .ju367v2 {
  align-items: flex-end;
}

[data-rk] .ju367v4 {
  align-items: center;
}

[data-rk] .ju367v6 {
  display: none;
}

[data-rk] .ju367v8 {
  display: block;
}

[data-rk] .ju367va {
  display: flex;
}

[data-rk] .ju367vc {
  display: inline;
}

[data-rk] .ju367ve {
  align-self: flex-start;
}

[data-rk] .ju367vf {
  align-self: flex-end;
}

[data-rk] .ju367vg {
  align-self: center;
}

[data-rk] .ju367vh {
  background-size: cover;
}

[data-rk] .ju367vi {
  border-radius: 1px;
}

[data-rk] .ju367vj {
  border-radius: 6px;
}

[data-rk] .ju367vk {
  border-radius: 10px;
}

[data-rk] .ju367vl {
  border-radius: 13px;
}

[data-rk] .ju367vm {
  border-radius: var(--rk-radii-actionButton);
}

[data-rk] .ju367vn {
  border-radius: var(--rk-radii-connectButton);
}

[data-rk] .ju367vo {
  border-radius: var(--rk-radii-menuButton);
}

[data-rk] .ju367vp {
  border-radius: var(--rk-radii-modal);
}

[data-rk] .ju367vq {
  border-radius: var(--rk-radii-modalMobile);
}

[data-rk] .ju367vr {
  border-radius: 25%;
}

[data-rk] .ju367vs {
  border-radius: 9999px;
}

[data-rk] .ju367vt {
  border-style: solid;
}

[data-rk] .ju367vu {
  border-width: 0;
}

[data-rk] .ju367vv {
  border-width: 1px;
}

[data-rk] .ju367vw {
  border-width: 2px;
}

[data-rk] .ju367vx {
  border-width: 4px;
}

[data-rk] .ju367vy {
  cursor: pointer;
}

[data-rk] .ju367vz {
  flex-direction: row;
}

[data-rk] .ju367v10 {
  flex-direction: column;
}

[data-rk] .ju367v11 {
  font-family: var(--rk-fonts-body);
}

[data-rk] .ju367v12 {
  font-size: 12px;
  line-height: 18px;
}

[data-rk] .ju367v13 {
  font-size: 13px;
  line-height: 18px;
}

[data-rk] .ju367v14 {
  font-size: 14px;
  line-height: 18px;
}

[data-rk] .ju367v15 {
  font-size: 16px;
  line-height: 20px;
}

[data-rk] .ju367v16 {
  font-size: 18px;
  line-height: 24px;
}

[data-rk] .ju367v17 {
  font-size: 20px;
  line-height: 24px;
}

[data-rk] .ju367v18 {
  font-size: 23px;
  line-height: 29px;
}

[data-rk] .ju367v19 {
  font-weight: 400;
}

[data-rk] .ju367v1a {
  font-weight: 500;
}

[data-rk] .ju367v1b {
  font-weight: 600;
}

[data-rk] .ju367v1c {
  font-weight: 700;
}

[data-rk] .ju367v1d {
  font-weight: 800;
}

[data-rk] .ju367v1e {
  gap: 0;
}

[data-rk] .ju367v1f {
  gap: 1px;
}

[data-rk] .ju367v1g {
  gap: 2px;
}

[data-rk] .ju367v1h {
  gap: 3px;
}

[data-rk] .ju367v1i {
  gap: 4px;
}

[data-rk] .ju367v1j {
  gap: 5px;
}

[data-rk] .ju367v1k {
  gap: 6px;
}

[data-rk] .ju367v1l {
  gap: 8px;
}

[data-rk] .ju367v1m {
  gap: 10px;
}

[data-rk] .ju367v1n {
  gap: 12px;
}

[data-rk] .ju367v1o {
  gap: 14px;
}

[data-rk] .ju367v1p {
  gap: 16px;
}

[data-rk] .ju367v1q {
  gap: 18px;
}

[data-rk] .ju367v1r {
  gap: 20px;
}

[data-rk] .ju367v1s {
  gap: 24px;
}

[data-rk] .ju367v1t {
  gap: 28px;
}

[data-rk] .ju367v1u {
  gap: 32px;
}

[data-rk] .ju367v1v {
  gap: 36px;
}

[data-rk] .ju367v1w {
  gap: 44px;
}

[data-rk] .ju367v1x {
  gap: 64px;
}

[data-rk] .ju367v1y {
  gap: -1px;
}

[data-rk] .ju367v1z {
  height: 1px;
}

[data-rk] .ju367v20 {
  height: 2px;
}

[data-rk] .ju367v21 {
  height: 4px;
}

[data-rk] .ju367v22 {
  height: 8px;
}

[data-rk] .ju367v23 {
  height: 12px;
}

[data-rk] .ju367v24 {
  height: 20px;
}

[data-rk] .ju367v25 {
  height: 24px;
}

[data-rk] .ju367v26 {
  height: 28px;
}

[data-rk] .ju367v27 {
  height: 30px;
}

[data-rk] .ju367v28 {
  height: 32px;
}

[data-rk] .ju367v29 {
  height: 34px;
}

[data-rk] .ju367v2a {
  height: 36px;
}

[data-rk] .ju367v2b {
  height: 40px;
}

[data-rk] .ju367v2c {
  height: 44px;
}

[data-rk] .ju367v2d {
  height: 48px;
}

[data-rk] .ju367v2e {
  height: 54px;
}

[data-rk] .ju367v2f {
  height: 60px;
}

[data-rk] .ju367v2g {
  height: 200px;
}

[data-rk] .ju367v2h {
  height: 100%;
}

[data-rk] .ju367v2i {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: -webkit-max-content;
  height: max-content;
}

[data-rk] .ju367v2j {
  justify-content: flex-start;
}

[data-rk] .ju367v2k {
  justify-content: flex-end;
}

[data-rk] .ju367v2l {
  justify-content: center;
}

[data-rk] .ju367v2m {
  justify-content: space-between;
}

[data-rk] .ju367v2n {
  justify-content: space-around;
}

[data-rk] .ju367v2o {
  text-align: left;
}

[data-rk] .ju367v2p {
  text-align: center;
}

[data-rk] .ju367v2q {
  text-align: inherit;
}

[data-rk] .ju367v2r {
  margin-bottom: 0;
}

[data-rk] .ju367v2s {
  margin-bottom: 1px;
}

[data-rk] .ju367v2t {
  margin-bottom: 2px;
}

[data-rk] .ju367v2u {
  margin-bottom: 3px;
}

[data-rk] .ju367v2v {
  margin-bottom: 4px;
}

[data-rk] .ju367v2w {
  margin-bottom: 5px;
}

[data-rk] .ju367v2x {
  margin-bottom: 6px;
}

[data-rk] .ju367v2y {
  margin-bottom: 8px;
}

[data-rk] .ju367v2z {
  margin-bottom: 10px;
}

[data-rk] .ju367v30 {
  margin-bottom: 12px;
}

[data-rk] .ju367v31 {
  margin-bottom: 14px;
}

[data-rk] .ju367v32 {
  margin-bottom: 16px;
}

[data-rk] .ju367v33 {
  margin-bottom: 18px;
}

[data-rk] .ju367v34 {
  margin-bottom: 20px;
}

[data-rk] .ju367v35 {
  margin-bottom: 24px;
}

[data-rk] .ju367v36 {
  margin-bottom: 28px;
}

[data-rk] .ju367v37 {
  margin-bottom: 32px;
}

[data-rk] .ju367v38 {
  margin-bottom: 36px;
}

[data-rk] .ju367v39 {
  margin-bottom: 44px;
}

[data-rk] .ju367v3a {
  margin-bottom: 64px;
}

[data-rk] .ju367v3b {
  margin-bottom: -1px;
}

[data-rk] .ju367v3c {
  margin-left: 0;
}

[data-rk] .ju367v3d {
  margin-left: 1px;
}

[data-rk] .ju367v3e {
  margin-left: 2px;
}

[data-rk] .ju367v3f {
  margin-left: 3px;
}

[data-rk] .ju367v3g {
  margin-left: 4px;
}

[data-rk] .ju367v3h {
  margin-left: 5px;
}

[data-rk] .ju367v3i {
  margin-left: 6px;
}

[data-rk] .ju367v3j {
  margin-left: 8px;
}

[data-rk] .ju367v3k {
  margin-left: 10px;
}

[data-rk] .ju367v3l {
  margin-left: 12px;
}

[data-rk] .ju367v3m {
  margin-left: 14px;
}

[data-rk] .ju367v3n {
  margin-left: 16px;
}

[data-rk] .ju367v3o {
  margin-left: 18px;
}

[data-rk] .ju367v3p {
  margin-left: 20px;
}

[data-rk] .ju367v3q {
  margin-left: 24px;
}

[data-rk] .ju367v3r {
  margin-left: 28px;
}

[data-rk] .ju367v3s {
  margin-left: 32px;
}

[data-rk] .ju367v3t {
  margin-left: 36px;
}

[data-rk] .ju367v3u {
  margin-left: 44px;
}

[data-rk] .ju367v3v {
  margin-left: 64px;
}

[data-rk] .ju367v3w {
  margin-left: -1px;
}

[data-rk] .ju367v3x {
  margin-right: 0;
}

[data-rk] .ju367v3y {
  margin-right: 1px;
}

[data-rk] .ju367v3z {
  margin-right: 2px;
}

[data-rk] .ju367v40 {
  margin-right: 3px;
}

[data-rk] .ju367v41 {
  margin-right: 4px;
}

[data-rk] .ju367v42 {
  margin-right: 5px;
}

[data-rk] .ju367v43 {
  margin-right: 6px;
}

[data-rk] .ju367v44 {
  margin-right: 8px;
}

[data-rk] .ju367v45 {
  margin-right: 10px;
}

[data-rk] .ju367v46 {
  margin-right: 12px;
}

[data-rk] .ju367v47 {
  margin-right: 14px;
}

[data-rk] .ju367v48 {
  margin-right: 16px;
}

[data-rk] .ju367v49 {
  margin-right: 18px;
}

[data-rk] .ju367v4a {
  margin-right: 20px;
}

[data-rk] .ju367v4b {
  margin-right: 24px;
}

[data-rk] .ju367v4c {
  margin-right: 28px;
}

[data-rk] .ju367v4d {
  margin-right: 32px;
}

[data-rk] .ju367v4e {
  margin-right: 36px;
}

[data-rk] .ju367v4f {
  margin-right: 44px;
}

[data-rk] .ju367v4g {
  margin-right: 64px;
}

[data-rk] .ju367v4h {
  margin-right: -1px;
}

[data-rk] .ju367v4i {
  margin-top: 0;
}

[data-rk] .ju367v4j {
  margin-top: 1px;
}

[data-rk] .ju367v4k {
  margin-top: 2px;
}

[data-rk] .ju367v4l {
  margin-top: 3px;
}

[data-rk] .ju367v4m {
  margin-top: 4px;
}

[data-rk] .ju367v4n {
  margin-top: 5px;
}

[data-rk] .ju367v4o {
  margin-top: 6px;
}

[data-rk] .ju367v4p {
  margin-top: 8px;
}

[data-rk] .ju367v4q {
  margin-top: 10px;
}

[data-rk] .ju367v4r {
  margin-top: 12px;
}

[data-rk] .ju367v4s {
  margin-top: 14px;
}

[data-rk] .ju367v4t {
  margin-top: 16px;
}

[data-rk] .ju367v4u {
  margin-top: 18px;
}

[data-rk] .ju367v4v {
  margin-top: 20px;
}

[data-rk] .ju367v4w {
  margin-top: 24px;
}

[data-rk] .ju367v4x {
  margin-top: 28px;
}

[data-rk] .ju367v4y {
  margin-top: 32px;
}

[data-rk] .ju367v4z {
  margin-top: 36px;
}

[data-rk] .ju367v50 {
  margin-top: 44px;
}

[data-rk] .ju367v51 {
  margin-top: 64px;
}

[data-rk] .ju367v52 {
  margin-top: -1px;
}

[data-rk] .ju367v53 {
  max-width: 1px;
}

[data-rk] .ju367v54 {
  max-width: 2px;
}

[data-rk] .ju367v55 {
  max-width: 4px;
}

[data-rk] .ju367v56 {
  max-width: 8px;
}

[data-rk] .ju367v57 {
  max-width: 12px;
}

[data-rk] .ju367v58 {
  max-width: 20px;
}

[data-rk] .ju367v59 {
  max-width: 24px;
}

[data-rk] .ju367v5a {
  max-width: 28px;
}

[data-rk] .ju367v5b {
  max-width: 30px;
}

[data-rk] .ju367v5c {
  max-width: 32px;
}

[data-rk] .ju367v5d {
  max-width: 34px;
}

[data-rk] .ju367v5e {
  max-width: 36px;
}

[data-rk] .ju367v5f {
  max-width: 40px;
}

[data-rk] .ju367v5g {
  max-width: 44px;
}

[data-rk] .ju367v5h {
  max-width: 48px;
}

[data-rk] .ju367v5i {
  max-width: 54px;
}

[data-rk] .ju367v5j {
  max-width: 60px;
}

[data-rk] .ju367v5k {
  max-width: 200px;
}

[data-rk] .ju367v5l {
  max-width: 100%;
}

[data-rk] .ju367v5m {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: -webkit-max-content;
  max-width: max-content;
}

[data-rk] .ju367v5n {
  min-width: 1px;
}

[data-rk] .ju367v5o {
  min-width: 2px;
}

[data-rk] .ju367v5p {
  min-width: 4px;
}

[data-rk] .ju367v5q {
  min-width: 8px;
}

[data-rk] .ju367v5r {
  min-width: 12px;
}

[data-rk] .ju367v5s {
  min-width: 20px;
}

[data-rk] .ju367v5t {
  min-width: 24px;
}

[data-rk] .ju367v5u {
  min-width: 28px;
}

[data-rk] .ju367v5v {
  min-width: 30px;
}

[data-rk] .ju367v5w {
  min-width: 32px;
}

[data-rk] .ju367v5x {
  min-width: 34px;
}

[data-rk] .ju367v5y {
  min-width: 36px;
}

[data-rk] .ju367v5z {
  min-width: 40px;
}

[data-rk] .ju367v60 {
  min-width: 44px;
}

[data-rk] .ju367v61 {
  min-width: 48px;
}

[data-rk] .ju367v62 {
  min-width: 54px;
}

[data-rk] .ju367v63 {
  min-width: 60px;
}

[data-rk] .ju367v64 {
  min-width: 200px;
}

[data-rk] .ju367v65 {
  min-width: 100%;
}

[data-rk] .ju367v66 {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: -webkit-max-content;
  min-width: max-content;
}

[data-rk] .ju367v67 {
  overflow: hidden;
}

[data-rk] .ju367v68 {
  padding-bottom: 0;
}

[data-rk] .ju367v69 {
  padding-bottom: 1px;
}

[data-rk] .ju367v6a {
  padding-bottom: 2px;
}

[data-rk] .ju367v6b {
  padding-bottom: 3px;
}

[data-rk] .ju367v6c {
  padding-bottom: 4px;
}

[data-rk] .ju367v6d {
  padding-bottom: 5px;
}

[data-rk] .ju367v6e {
  padding-bottom: 6px;
}

[data-rk] .ju367v6f {
  padding-bottom: 8px;
}

[data-rk] .ju367v6g {
  padding-bottom: 10px;
}

[data-rk] .ju367v6h {
  padding-bottom: 12px;
}

[data-rk] .ju367v6i {
  padding-bottom: 14px;
}

[data-rk] .ju367v6j {
  padding-bottom: 16px;
}

[data-rk] .ju367v6k {
  padding-bottom: 18px;
}

[data-rk] .ju367v6l {
  padding-bottom: 20px;
}

[data-rk] .ju367v6m {
  padding-bottom: 24px;
}

[data-rk] .ju367v6n {
  padding-bottom: 28px;
}

[data-rk] .ju367v6o {
  padding-bottom: 32px;
}

[data-rk] .ju367v6p {
  padding-bottom: 36px;
}

[data-rk] .ju367v6q {
  padding-bottom: 44px;
}

[data-rk] .ju367v6r {
  padding-bottom: 64px;
}

[data-rk] .ju367v6s {
  padding-bottom: -1px;
}

[data-rk] .ju367v6t {
  padding-left: 0;
}

[data-rk] .ju367v6u {
  padding-left: 1px;
}

[data-rk] .ju367v6v {
  padding-left: 2px;
}

[data-rk] .ju367v6w {
  padding-left: 3px;
}

[data-rk] .ju367v6x {
  padding-left: 4px;
}

[data-rk] .ju367v6y {
  padding-left: 5px;
}

[data-rk] .ju367v6z {
  padding-left: 6px;
}

[data-rk] .ju367v70 {
  padding-left: 8px;
}

[data-rk] .ju367v71 {
  padding-left: 10px;
}

[data-rk] .ju367v72 {
  padding-left: 12px;
}

[data-rk] .ju367v73 {
  padding-left: 14px;
}

[data-rk] .ju367v74 {
  padding-left: 16px;
}

[data-rk] .ju367v75 {
  padding-left: 18px;
}

[data-rk] .ju367v76 {
  padding-left: 20px;
}

[data-rk] .ju367v77 {
  padding-left: 24px;
}

[data-rk] .ju367v78 {
  padding-left: 28px;
}

[data-rk] .ju367v79 {
  padding-left: 32px;
}

[data-rk] .ju367v7a {
  padding-left: 36px;
}

[data-rk] .ju367v7b {
  padding-left: 44px;
}

[data-rk] .ju367v7c {
  padding-left: 64px;
}

[data-rk] .ju367v7d {
  padding-left: -1px;
}

[data-rk] .ju367v7e {
  padding-right: 0;
}

[data-rk] .ju367v7f {
  padding-right: 1px;
}

[data-rk] .ju367v7g {
  padding-right: 2px;
}

[data-rk] .ju367v7h {
  padding-right: 3px;
}

[data-rk] .ju367v7i {
  padding-right: 4px;
}

[data-rk] .ju367v7j {
  padding-right: 5px;
}

[data-rk] .ju367v7k {
  padding-right: 6px;
}

[data-rk] .ju367v7l {
  padding-right: 8px;
}

[data-rk] .ju367v7m {
  padding-right: 10px;
}

[data-rk] .ju367v7n {
  padding-right: 12px;
}

[data-rk] .ju367v7o {
  padding-right: 14px;
}

[data-rk] .ju367v7p {
  padding-right: 16px;
}

[data-rk] .ju367v7q {
  padding-right: 18px;
}

[data-rk] .ju367v7r {
  padding-right: 20px;
}

[data-rk] .ju367v7s {
  padding-right: 24px;
}

[data-rk] .ju367v7t {
  padding-right: 28px;
}

[data-rk] .ju367v7u {
  padding-right: 32px;
}

[data-rk] .ju367v7v {
  padding-right: 36px;
}

[data-rk] .ju367v7w {
  padding-right: 44px;
}

[data-rk] .ju367v7x {
  padding-right: 64px;
}

[data-rk] .ju367v7y {
  padding-right: -1px;
}

[data-rk] .ju367v7z {
  padding-top: 0;
}

[data-rk] .ju367v80 {
  padding-top: 1px;
}

[data-rk] .ju367v81 {
  padding-top: 2px;
}

[data-rk] .ju367v82 {
  padding-top: 3px;
}

[data-rk] .ju367v83 {
  padding-top: 4px;
}

[data-rk] .ju367v84 {
  padding-top: 5px;
}

[data-rk] .ju367v85 {
  padding-top: 6px;
}

[data-rk] .ju367v86 {
  padding-top: 8px;
}

[data-rk] .ju367v87 {
  padding-top: 10px;
}

[data-rk] .ju367v88 {
  padding-top: 12px;
}

[data-rk] .ju367v89 {
  padding-top: 14px;
}

[data-rk] .ju367v8a {
  padding-top: 16px;
}

[data-rk] .ju367v8b {
  padding-top: 18px;
}

[data-rk] .ju367v8c {
  padding-top: 20px;
}

[data-rk] .ju367v8d {
  padding-top: 24px;
}

[data-rk] .ju367v8e {
  padding-top: 28px;
}

[data-rk] .ju367v8f {
  padding-top: 32px;
}

[data-rk] .ju367v8g {
  padding-top: 36px;
}

[data-rk] .ju367v8h {
  padding-top: 44px;
}

[data-rk] .ju367v8i {
  padding-top: 64px;
}

[data-rk] .ju367v8j {
  padding-top: -1px;
}

[data-rk] .ju367v8k {
  position: absolute;
}

[data-rk] .ju367v8l {
  position: fixed;
}

[data-rk] .ju367v8m {
  position: relative;
}

[data-rk] .ju367v8n {
  right: 0;
}

[data-rk] .ju367v8o {
  transition: all .125s;
}

[data-rk] .ju367v8p {
  transition: -webkit-transform .125s, transform .125s;
}

[data-rk] .ju367v8q {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-rk] .ju367v8r {
  width: 1px;
}

[data-rk] .ju367v8s {
  width: 2px;
}

[data-rk] .ju367v8t {
  width: 4px;
}

[data-rk] .ju367v8u {
  width: 8px;
}

[data-rk] .ju367v8v {
  width: 12px;
}

[data-rk] .ju367v8w {
  width: 20px;
}

[data-rk] .ju367v8x {
  width: 24px;
}

[data-rk] .ju367v8y {
  width: 28px;
}

[data-rk] .ju367v8z {
  width: 30px;
}

[data-rk] .ju367v90 {
  width: 32px;
}

[data-rk] .ju367v91 {
  width: 34px;
}

[data-rk] .ju367v92 {
  width: 36px;
}

[data-rk] .ju367v93 {
  width: 40px;
}

[data-rk] .ju367v94 {
  width: 44px;
}

[data-rk] .ju367v95 {
  width: 48px;
}

[data-rk] .ju367v96 {
  width: 54px;
}

[data-rk] .ju367v97 {
  width: 60px;
}

[data-rk] .ju367v98 {
  width: 200px;
}

[data-rk] .ju367v99 {
  width: 100%;
}

[data-rk] .ju367v9a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
}

[data-rk] .ju367v9b {
  -webkit-backdrop-filter: var(--rk-blurs-modalOverlay);
  -webkit-backdrop-filter: var(--rk-blurs-modalOverlay);
  backdrop-filter: var(--rk-blurs-modalOverlay);
}

[data-rk] .ju367v9c, [data-rk] .ju367v9d:hover, [data-rk] .ju367v9e:active {
  background: var(--rk-colors-accentColor);
}

[data-rk] .ju367v9f, [data-rk] .ju367v9g:hover, [data-rk] .ju367v9h:active {
  background: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367v9i, [data-rk] .ju367v9j:hover, [data-rk] .ju367v9k:active {
  background: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367v9l, [data-rk] .ju367v9m:hover, [data-rk] .ju367v9n:active {
  background: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367v9o, [data-rk] .ju367v9p:hover, [data-rk] .ju367v9q:active {
  background: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367v9r, [data-rk] .ju367v9s:hover, [data-rk] .ju367v9t:active {
  background: var(--rk-colors-closeButton);
}

[data-rk] .ju367v9u, [data-rk] .ju367v9v:hover, [data-rk] .ju367v9w:active {
  background: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367v9x, [data-rk] .ju367v9y:hover, [data-rk] .ju367v9z:active {
  background: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367va0, [data-rk] .ju367va1:hover, [data-rk] .ju367va2:active {
  background: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367va3, [data-rk] .ju367va4:hover, [data-rk] .ju367va5:active {
  background: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367va6, [data-rk] .ju367va7:hover, [data-rk] .ju367va8:active {
  background: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367va9, [data-rk] .ju367vaa:hover, [data-rk] .ju367vab:active {
  background: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vac, [data-rk] .ju367vad:hover, [data-rk] .ju367vae:active {
  background: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vaf, [data-rk] .ju367vag:hover, [data-rk] .ju367vah:active {
  background: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vai, [data-rk] .ju367vaj:hover, [data-rk] .ju367vak:active {
  background: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367val, [data-rk] .ju367vam:hover, [data-rk] .ju367van:active {
  background: var(--rk-colors-error);
}

[data-rk] .ju367vao, [data-rk] .ju367vap:hover, [data-rk] .ju367vaq:active {
  background: var(--rk-colors-generalBorder);
}

[data-rk] .ju367var, [data-rk] .ju367vas:hover, [data-rk] .ju367vat:active {
  background: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vau, [data-rk] .ju367vav:hover, [data-rk] .ju367vaw:active {
  background: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vax, [data-rk] .ju367vay:hover, [data-rk] .ju367vaz:active {
  background: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vb0, [data-rk] .ju367vb1:hover, [data-rk] .ju367vb2:active {
  background: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vb3, [data-rk] .ju367vb4:hover, [data-rk] .ju367vb5:active {
  background: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vb6, [data-rk] .ju367vb7:hover, [data-rk] .ju367vb8:active {
  background: var(--rk-colors-modalText);
}

[data-rk] .ju367vb9, [data-rk] .ju367vba:hover, [data-rk] .ju367vbb:active {
  background: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vbc, [data-rk] .ju367vbd:hover, [data-rk] .ju367vbe:active {
  background: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vbf, [data-rk] .ju367vbg:hover, [data-rk] .ju367vbh:active {
  background: var(--rk-colors-profileAction);
}

[data-rk] .ju367vbi, [data-rk] .ju367vbj:hover, [data-rk] .ju367vbk:active {
  background: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367vbl, [data-rk] .ju367vbm:hover, [data-rk] .ju367vbn:active {
  background: var(--rk-colors-profileForeground);
}

[data-rk] .ju367vbo, [data-rk] .ju367vbp:hover, [data-rk] .ju367vbq:active {
  background: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367vbr, [data-rk] .ju367vbs:hover, [data-rk] .ju367vbt:active {
  background: var(--rk-colors-standby);
}

[data-rk] .ju367vbu, [data-rk] .ju367vbv:hover, [data-rk] .ju367vbw:active {
  border-color: var(--rk-colors-accentColor);
}

[data-rk] .ju367vbx, [data-rk] .ju367vby:hover, [data-rk] .ju367vbz:active {
  border-color: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367vc0, [data-rk] .ju367vc1:hover, [data-rk] .ju367vc2:active {
  border-color: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367vc3, [data-rk] .ju367vc4:hover, [data-rk] .ju367vc5:active {
  border-color: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367vc6, [data-rk] .ju367vc7:hover, [data-rk] .ju367vc8:active {
  border-color: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367vc9, [data-rk] .ju367vca:hover, [data-rk] .ju367vcb:active {
  border-color: var(--rk-colors-closeButton);
}

[data-rk] .ju367vcc, [data-rk] .ju367vcd:hover, [data-rk] .ju367vce:active {
  border-color: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367vcf, [data-rk] .ju367vcg:hover, [data-rk] .ju367vch:active {
  border-color: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367vci, [data-rk] .ju367vcj:hover, [data-rk] .ju367vck:active {
  border-color: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367vcl, [data-rk] .ju367vcm:hover, [data-rk] .ju367vcn:active {
  border-color: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367vco, [data-rk] .ju367vcp:hover, [data-rk] .ju367vcq:active {
  border-color: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367vcr, [data-rk] .ju367vcs:hover, [data-rk] .ju367vct:active {
  border-color: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vcu, [data-rk] .ju367vcv:hover, [data-rk] .ju367vcw:active {
  border-color: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vcx, [data-rk] .ju367vcy:hover, [data-rk] .ju367vcz:active {
  border-color: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vd0, [data-rk] .ju367vd1:hover, [data-rk] .ju367vd2:active {
  border-color: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367vd3, [data-rk] .ju367vd4:hover, [data-rk] .ju367vd5:active {
  border-color: var(--rk-colors-error);
}

[data-rk] .ju367vd6, [data-rk] .ju367vd7:hover, [data-rk] .ju367vd8:active {
  border-color: var(--rk-colors-generalBorder);
}

[data-rk] .ju367vd9, [data-rk] .ju367vda:hover, [data-rk] .ju367vdb:active {
  border-color: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vdc, [data-rk] .ju367vdd:hover, [data-rk] .ju367vde:active {
  border-color: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vdf, [data-rk] .ju367vdg:hover, [data-rk] .ju367vdh:active {
  border-color: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vdi, [data-rk] .ju367vdj:hover, [data-rk] .ju367vdk:active {
  border-color: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vdl, [data-rk] .ju367vdm:hover, [data-rk] .ju367vdn:active {
  border-color: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vdo, [data-rk] .ju367vdp:hover, [data-rk] .ju367vdq:active {
  border-color: var(--rk-colors-modalText);
}

[data-rk] .ju367vdr, [data-rk] .ju367vds:hover, [data-rk] .ju367vdt:active {
  border-color: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vdu, [data-rk] .ju367vdv:hover, [data-rk] .ju367vdw:active {
  border-color: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vdx, [data-rk] .ju367vdy:hover, [data-rk] .ju367vdz:active {
  border-color: var(--rk-colors-profileAction);
}

[data-rk] .ju367ve0, [data-rk] .ju367ve1:hover, [data-rk] .ju367ve2:active {
  border-color: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367ve3, [data-rk] .ju367ve4:hover, [data-rk] .ju367ve5:active {
  border-color: var(--rk-colors-profileForeground);
}

[data-rk] .ju367ve6, [data-rk] .ju367ve7:hover, [data-rk] .ju367ve8:active {
  border-color: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367ve9, [data-rk] .ju367vea:hover, [data-rk] .ju367veb:active {
  border-color: var(--rk-colors-standby);
}

[data-rk] .ju367vec, [data-rk] .ju367ved:hover, [data-rk] .ju367vee:active {
  box-shadow: var(--rk-shadows-connectButton);
}

[data-rk] .ju367vef, [data-rk] .ju367veg:hover, [data-rk] .ju367veh:active {
  box-shadow: var(--rk-shadows-dialog);
}

[data-rk] .ju367vei, [data-rk] .ju367vej:hover, [data-rk] .ju367vek:active {
  box-shadow: var(--rk-shadows-profileDetailsAction);
}

[data-rk] .ju367vel, [data-rk] .ju367vem:hover, [data-rk] .ju367ven:active {
  box-shadow: var(--rk-shadows-selectedOption);
}

[data-rk] .ju367veo, [data-rk] .ju367vep:hover, [data-rk] .ju367veq:active {
  box-shadow: var(--rk-shadows-selectedWallet);
}

[data-rk] .ju367ver, [data-rk] .ju367ves:hover, [data-rk] .ju367vet:active {
  box-shadow: var(--rk-shadows-walletLogo);
}

[data-rk] .ju367veu, [data-rk] .ju367vev:hover, [data-rk] .ju367vew:active {
  color: var(--rk-colors-accentColor);
}

[data-rk] .ju367vex, [data-rk] .ju367vey:hover, [data-rk] .ju367vez:active {
  color: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367vf0, [data-rk] .ju367vf1:hover, [data-rk] .ju367vf2:active {
  color: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367vf3, [data-rk] .ju367vf4:hover, [data-rk] .ju367vf5:active {
  color: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367vf6, [data-rk] .ju367vf7:hover, [data-rk] .ju367vf8:active {
  color: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367vf9, [data-rk] .ju367vfa:hover, [data-rk] .ju367vfb:active {
  color: var(--rk-colors-closeButton);
}

[data-rk] .ju367vfc, [data-rk] .ju367vfd:hover, [data-rk] .ju367vfe:active {
  color: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367vff, [data-rk] .ju367vfg:hover, [data-rk] .ju367vfh:active {
  color: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367vfi, [data-rk] .ju367vfj:hover, [data-rk] .ju367vfk:active {
  color: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367vfl, [data-rk] .ju367vfm:hover, [data-rk] .ju367vfn:active {
  color: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367vfo, [data-rk] .ju367vfp:hover, [data-rk] .ju367vfq:active {
  color: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367vfr, [data-rk] .ju367vfs:hover, [data-rk] .ju367vft:active {
  color: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vfu, [data-rk] .ju367vfv:hover, [data-rk] .ju367vfw:active {
  color: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vfx, [data-rk] .ju367vfy:hover, [data-rk] .ju367vfz:active {
  color: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vg0, [data-rk] .ju367vg1:hover, [data-rk] .ju367vg2:active {
  color: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367vg3, [data-rk] .ju367vg4:hover, [data-rk] .ju367vg5:active {
  color: var(--rk-colors-error);
}

[data-rk] .ju367vg6, [data-rk] .ju367vg7:hover, [data-rk] .ju367vg8:active {
  color: var(--rk-colors-generalBorder);
}

[data-rk] .ju367vg9, [data-rk] .ju367vga:hover, [data-rk] .ju367vgb:active {
  color: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vgc, [data-rk] .ju367vgd:hover, [data-rk] .ju367vge:active {
  color: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vgf, [data-rk] .ju367vgg:hover, [data-rk] .ju367vgh:active {
  color: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vgi, [data-rk] .ju367vgj:hover, [data-rk] .ju367vgk:active {
  color: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vgl, [data-rk] .ju367vgm:hover, [data-rk] .ju367vgn:active {
  color: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vgo, [data-rk] .ju367vgp:hover, [data-rk] .ju367vgq:active {
  color: var(--rk-colors-modalText);
}

[data-rk] .ju367vgr, [data-rk] .ju367vgs:hover, [data-rk] .ju367vgt:active {
  color: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vgu, [data-rk] .ju367vgv:hover, [data-rk] .ju367vgw:active {
  color: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vgx, [data-rk] .ju367vgy:hover, [data-rk] .ju367vgz:active {
  color: var(--rk-colors-profileAction);
}

[data-rk] .ju367vh0, [data-rk] .ju367vh1:hover, [data-rk] .ju367vh2:active {
  color: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367vh3, [data-rk] .ju367vh4:hover, [data-rk] .ju367vh5:active {
  color: var(--rk-colors-profileForeground);
}

[data-rk] .ju367vh6, [data-rk] .ju367vh7:hover, [data-rk] .ju367vh8:active {
  color: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367vh9, [data-rk] .ju367vha:hover, [data-rk] .ju367vhb:active {
  color: var(--rk-colors-standby);
}

@media screen and (min-width: 768px) {
  [data-rk] .ju367v1 {
    align-items: flex-start;
  }

  [data-rk] .ju367v3 {
    align-items: flex-end;
  }

  [data-rk] .ju367v5 {
    align-items: center;
  }

  [data-rk] .ju367v7 {
    display: none;
  }

  [data-rk] .ju367v9 {
    display: block;
  }

  [data-rk] .ju367vb {
    display: flex;
  }

  [data-rk] .ju367vd {
    display: inline;
  }
}

[data-rk] ._12cbo8i3, [data-rk] ._12cbo8i3:after {
  --_12cbo8i0: 1;
  --_12cbo8i1: 1;
}

[data-rk] ._12cbo8i3:hover {
  -webkit-transform: scale(var(--_12cbo8i0));
  transform: scale(var(--_12cbo8i0));
}

[data-rk] ._12cbo8i3:active {
  -webkit-transform: scale(var(--_12cbo8i1));
  transform: scale(var(--_12cbo8i1));
}

[data-rk] ._12cbo8i3:active:after {
  content: "";
  -webkit-transform: scale(calc((1 / var(--_12cbo8i1)) * var(--_12cbo8i0)) );
  transform: scale(calc((1 / var(--_12cbo8i1)) * var(--_12cbo8i0)) );
  display: block;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

[data-rk] ._12cbo8i4, [data-rk] ._12cbo8i4:after {
  --_12cbo8i0: 1.025;
}

[data-rk] ._12cbo8i5, [data-rk] ._12cbo8i5:after {
  --_12cbo8i0: 1.1;
}

[data-rk] ._12cbo8i6, [data-rk] ._12cbo8i6:after {
  --_12cbo8i1: .95;
}

[data-rk] ._12cbo8i7, [data-rk] ._12cbo8i7:after {
  --_12cbo8i1: .9;
}

@-webkit-keyframes _1luule41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes _1luule41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[data-rk] ._1luule42 {
  -webkit-animation: _1luule41 3s linear infinite;
  animation: _1luule41 3s linear infinite;
}

[data-rk] ._1luule43 {
  height: 21px;
  width: 21px;
  background: conic-gradient(from 180deg, rgba(72, 146, 254, 0) 0deg, currentColor 282.04deg, rgba(72, 146, 254, 0) 319.86deg, rgba(72, 146, 254, 0) 360deg);
}

@-webkit-keyframes _9pm4ki0 {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes _9pm4ki0 {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes _9pm4ki1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes _9pm4ki1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

[data-rk] ._9pm4ki3 {
  z-index: 2147483646;
  padding: 200px;
  -webkit-animation: _9pm4ki1 .15s;
  animation: _9pm4ki1 .15s;
  top: -200px;
  bottom: -200px;
  left: -200px;
  right: -200px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

[data-rk] ._9pm4ki5 {
  max-width: 100vw;
  -webkit-animation: _9pm4ki0 .35s cubic-bezier(.15, 1.15, .6, 1), _9pm4ki1 .15s;
  animation: _9pm4ki0 .35s cubic-bezier(.15, 1.15, .6, 1), _9pm4ki1 .15s;
}

[data-rk] ._1ckjpok1 {
  box-sizing: content-box;
  max-width: 100vw;
  width: 360px;
}

[data-rk] ._1ckjpok2 {
  width: 100vw;
}

[data-rk] ._1ckjpok3 {
  width: 768px;
}

[data-rk] ._1ckjpok4 {
  min-width: 368px;
  width: 368px;
}

[data-rk] ._1ckjpok6 {
  box-sizing: border-box;
  width: 100vw;
  border-width: 0;
}

@media screen and (min-width: 768px) {
  [data-rk] ._1ckjpok1 {
    width: 360px;
  }

  [data-rk] ._1ckjpok2 {
    width: 480px;
  }

  [data-rk] ._1ckjpok3 {
    width: 720px;
  }

  [data-rk] ._1ckjpok4 {
    min-width: 368px;
    width: 368px;
  }
}

@media screen and (max-width: 767px) {
  [data-rk] ._1ckjpok7 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: -200px;
    padding-bottom: 200px;
    top: 200px;
  }
}

[data-rk] .v9horb0:hover {
  background: unset;
}

[data-rk] .g5kl0l0 {
  border-color: rgba(0, 0, 0, 0);
}

[data-rk] ._1vwt0cg0 {
  background: #fff;
}

[data-rk] ._1vwt0cg2 {
  max-height: 454px;
  overflow-y: auto;
}

[data-rk] ._1vwt0cg3 {
  min-width: 246px;
}

[data-rk] ._1vwt0cg4 {
  min-width: 100%;
}

@media screen and (min-width: 768px) {
  [data-rk] ._1vwt0cg3 {
    min-width: 287px;
  }
}

[data-rk] ._1am14410 {
  scrollbar-width: none;
  overflow: auto;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

[data-rk] ._1am14410::-webkit-scrollbar {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Catamaran;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid rgba(0, 0, 0, 0);
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.right-0 {
  right: 0;
}

.top-1\.5 {
  top: .375rem;
}

.left-1\.5 {
  left: .375rem;
}

.top-1 {
  top: .25rem;
}

.left-1 {
  left: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-1 {
  right: .25rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.right-5 {
  right: 1.25rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.top-0 {
  top: 0;
}

.top-8 {
  top: 2rem;
}

.right-4 {
  right: 1rem;
}

.top-4 {
  top: 1rem;
}

.right-28 {
  right: 7rem;
}

.right-2 {
  right: .5rem;
}

.top-2 {
  top: .5rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-0 {
  bottom: 0;
}

.top-10 {
  top: 2.5rem;
}

.right-3 {
  right: .75rem;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-0 {
  z-index: 0;
}

.z-30 {
  z-index: 30;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[500\] {
  z-index: 500;
}

.z-20 {
  z-index: 20;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.m-2 {
  margin: .5rem;
}

.m-0 {
  margin: 0;
}

.-m-3 {
  margin: -.75rem;
}

.m-8 {
  margin: 2rem;
}

.m-4 {
  margin: 1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-0 {
  margin-right: 0;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-auto {
  margin-left: auto;
}

.-mr-12 {
  margin-right: -3rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mr-8 {
  margin-right: 2rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.h-full {
  height: 100%;
}

.h-10 {
  height: 2.5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-9 {
  height: 2.25rem;
}

.h-auto {
  height: auto;
}

.h-48 {
  height: 12rem;
}

.h-80 {
  height: 20rem;
}

.h-14 {
  height: 3.5rem;
}

.h-\[64px\] {
  height: 64px;
}

.h-32 {
  height: 8rem;
}

.h-56 {
  height: 14rem;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[60px\] {
  height: 60px;
}

.h-modal {
  height: 600px;
}

.h-11 {
  height: 2.75rem;
}

.h-2 {
  height: .5rem;
}

.h-8 {
  height: 2rem;
}

.h-40 {
  height: 10rem;
}

.h-20 {
  height: 5rem;
}

.h-16 {
  height: 4rem;
}

.h-12 {
  height: 3rem;
}

.h-screen {
  height: 100vh;
}

.h-sidebar {
  height: calc(100% - 2.5rem);
}

.h-0 {
  height: 0;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-56 {
  max-height: 14rem;
}

.max-h-\[50px\] {
  max-height: 50px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-content {
  min-height: 81vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-min {
  min-height: -webkit-min-content;
  min-height: min-content;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-9 {
  width: 2.25rem;
}

.w-auto {
  width: auto;
}

.w-14 {
  width: 3.5rem;
}

.w-32 {
  width: 8rem;
}

.w-10 {
  width: 2.5rem;
}

.w-56 {
  width: 14rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-\[60px\] {
  width: 60px;
}

.w-modal {
  width: 400px;
}

.w-3 {
  width: .75rem;
}

.w-2 {
  width: .5rem;
}

.w-8 {
  width: 2rem;
}

.w-20 {
  width: 5rem;
}

.w-screen {
  width: 100vw;
}

.w-12 {
  width: 3rem;
}

.w-\[320px\] {
  width: 320px;
}

.w-16 {
  width: 4rem;
}

.w-0 {
  width: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-11 {
  width: 2.75rem;
}

.w-64 {
  width: 16rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-4 {
  width: 1rem;
}

.w-7 {
  width: 1.75rem;
}

.w-\[350px\] {
  width: 350px;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.min-w-card {
  min-width: 240px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.max-w-0 {
  max-width: 0;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-\[512px\] {
  max-width: 512px;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-\[140px\] {
  max-width: 140px;
}

.max-w-\[95vw\] {
  max-width: 95vw;
}

.flex-1 {
  flex: 1;
}

.flex-initial {
  flex: 0 auto;
}

.flex-\[0\] {
  flex: 0;
}

.flex-\[0\.5\] {
  flex: .5;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.animate-\[spin_4s_linear_infinite\] {
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.touch-auto {
  touch-action: auto;
}

.select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list-none {
  list-style-type: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-2 {
  gap: .5rem;
}

.gap-6 {
  gap: 1.5rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.divide-popupBackground > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(48, 48, 48, var(--tw-divide-opacity));
}

.divide-symbolFaint > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(228, 228, 228, var(--tw-divide-opacity));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-r {
  border-right-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.border-darkPopupSecondary {
  --tw-border-opacity: 1;
  border-color: rgba(228, 225, 222, var(--tw-border-opacity));
}

.border-symbolFaint {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 228, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.border-scrollbar {
  border-color: rgba(0, 0, 0, .2);
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgba(255, 104, 84, var(--tw-border-opacity));
}

.border-purpleLighter {
  --tw-border-opacity: 1;
  border-color: rgba(123, 102, 255, var(--tw-border-opacity));
}

.bg-darkPopupSecondary {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 225, 222, var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 0, 255, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-surface {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 242, 239, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-popupSecondary {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--tw-bg-opacity));
}

.bg-darkBackground {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}

.bg-popupPrimary, .bg-background {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 248, 244, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}

.bg-scrollbar {
  background-color: rgba(0, 0, 0, .2);
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.bg-purpleLighter {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 102, 255, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-darkSurface {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 26, 26, var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.from-purpleLighter {
  --tw-gradient-from: #7b66ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(123, 102, 255, 0));
}

.to-darkPrimary {
  --tw-gradient-to: #fff;
}

.fill-primary {
  fill: #000;
}

.fill-darkPrimary {
  fill: #fff;
}

.fill-popupBackground {
  fill: #303030;
}

.fill-symbolFaint {
  fill: #e4e4e4;
}

.stroke-popupBackground {
  stroke: #303030;
}

.stroke-primary {
  stroke: #000;
}

.stroke-\[1\] {
  stroke-width: 1px;
}

.object-fill {
  object-fit: fill;
}

.p-4 {
  padding: 1rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-3 {
  padding: .75rem;
}

.p-2 {
  padding: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-0 {
  padding-right: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-\[2px\] {
  padding-right: 2px;
}

.pl-0 {
  padding-left: 0;
}

.pb-\[1\.5px\] {
  padding-bottom: 1.5px;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-\[32px\] {
  padding-left: 32px;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-14 {
  padding-top: 3.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-pin {
  font-size: 24px;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-darkPrimary {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgba(255, 104, 84, var(--tw-text-opacity));
}

.text-darkSecondary {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-purpleLighter {
  --tw-text-opacity: 1;
  color: rgba(123, 102, 255, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(22, 163, 74, var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(74, 222, 128, var(--tw-text-opacity));
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgba(94, 0, 255, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.placeholder-gray-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.placeholder-gray-500::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.opacity-50 {
  opacity: .5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-70 {
  opacity: .7;
}

.shadow-card {
  --tw-shadow: 0px 4px 12px 0px rgba(0, 0, 0, .15), 0px 2px 2px 0px rgba(0, 0, 0, .3);
  --tw-shadow-colored: 0px 4px 12px 0px var(--tw-shadow-color), 0px 2px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.outline {
  outline-style: solid;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-100 {
  transition-duration: .1s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 20px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

[type="text"]:focus {
  --tw-ring-color: "#5E00FF";
}

[type="time"]:focus {
  --tw-ring-color: "#5E00FF";
}

.flatpickr-day.selected {
  background: #5e00ff !important;
  border-color: #5e00ff !important;
}

.dark .scrollbar-custom, .dark .scrollbar-custom-surface {
  scrollbar-color: rgba(255, 255, 255, .2) transparent;
  --scrollbar-color: rgba(255, 255, 255, .2) transparent;
}

.scrollbar-custom, .scrollbar-custom-surface, .surface-scroll {
  scrollbar-color: rgba(0, 0, 0, .2) transparent;
  --scrollbar-color: rgba(0, 0, 0, .2) transparent;
}

.scroll-provenance {
  scrollbar-color: transparent transparent;
  --scrollbar-color: transparent transparent;
}

.dark .surface-scroll {
  scrollbar-color: rgba(255, 255, 255, .2) transparent;
  --scrollbar-color: rgba(255, 255, 255, .2) transparent;
}

.flatpickr-calendar.noCalendar {
  width: 200px;
  border: 1px solid #5e00ff;
  outline-color: #5e00ff;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.focus-within\:text-gray-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.focus-within\:opacity-100:focus-within {
  opacity: 1;
}

.hover\:border-purpleLighter:hover {
  --tw-border-opacity: 1;
  border-color: rgba(123, 102, 255, var(--tw-border-opacity));
}

.hover\:bg-purpleLighter:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 102, 255, var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-darkPopupSecondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 225, 222, var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.hover\:bg-darkBackground:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}

.hover\:bg-surface:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 242, 239, var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:bg-symbolFaint:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 228, var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: rgba(0, 0, 0, 0);
}

.hover\:fill-purple:hover {
  fill: #5e00ff;
}

.hover\:stroke-purple:hover {
  stroke: #5e00ff;
}

.hover\:text-darkPrimary:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:text-purpleLighter:hover {
  --tw-text-opacity: 1;
  color: rgba(123, 102, 255, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.hover\:shadow-card:hover {
  --tw-shadow: 0px 4px 12px 0px rgba(0, 0, 0, .15), 0px 2px 2px 0px rgba(0, 0, 0, .3);
  --tw-shadow-colored: 0px 4px 12px 0px var(--tw-shadow-color), 0px 2px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.hover\:ring-purpleLighter:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(123, 102, 255, var(--tw-ring-opacity));
}

.focus\:rounded-full:focus {
  border-radius: 9999px;
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus\:border-transparent:focus {
  border-color: rgba(0, 0, 0, 0);
}

.focus\:border-error:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 104, 84, var(--tw-border-opacity));
}

.focus\:border-purpleLighter:focus {
  --tw-border-opacity: 1;
  border-color: rgba(123, 102, 255, var(--tw-border-opacity));
}

.focus\:border-sky-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(14, 165, 233, var(--tw-border-opacity));
}

.focus\:border-purple:focus {
  --tw-border-opacity: 1;
  border-color: rgba(94, 0, 255, var(--tw-border-opacity));
}

.focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-400:focus::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:outline-purple:focus {
  outline-color: #5e00ff;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus\:ring-purple:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(94, 0, 255, var(--tw-ring-opacity));
}

.focus\:ring-error:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 104, 84, var(--tw-ring-opacity));
}

.focus\:ring-purpleLighter:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(123, 102, 255, var(--tw-ring-opacity));
}

.focus\:ring-sky-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 165, 233, var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:border-purpleLighter {
  --tw-border-opacity: 1;
  border-color: rgba(123, 102, 255, var(--tw-border-opacity));
}

.group:hover .group-hover\:bg-purpleLighter {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 102, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.group:hover .group-hover\:fill-purpleLighter {
  fill: #7b66ff;
}

.group:hover .group-hover\:text-purpleLighter {
  --tw-text-opacity: 1;
  color: rgba(123, 102, 255, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.dark .dark\:divide-surface > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 242, 239, var(--tw-divide-opacity));
}

.dark .dark\:divide-popupSecondary > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(102, 102, 102, var(--tw-divide-opacity));
}

.dark .dark\:border-darkPrimary {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.dark .dark\:border-darkPopupBackground {
  --tw-border-opacity: 1;
  border-color: rgba(64, 64, 64, var(--tw-border-opacity));
}

.dark .dark\:border-darkScrollbar {
  border-color: rgba(255, 255, 255, .2);
}

.dark .dark\:bg-darkBackground {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}

.dark .dark\:bg-darkPrimary {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .dark\:bg-darkSurface {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 26, 26, var(--tw-bg-opacity));
}

.dark .dark\:bg-background {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 248, 244, var(--tw-bg-opacity));
}

.dark .dark\:bg-popupBackgroundSecondary {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}

.dark .dark\:bg-darkScrollbar {
  background-color: rgba(255, 255, 255, .2);
}

.dark .dark\:bg-popupBackground {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 48, 48, var(--tw-bg-opacity));
}

.dark .dark\:bg-darkPopupBackground {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}

.dark .dark\:fill-darkPrimary {
  fill: #fff;
}

.dark .dark\:fill-primary {
  fill: #000;
}

.dark .dark\:fill-popupPrimary {
  fill: #fbf8f4;
}

.dark .dark\:fill-popupBackgroundSecondary {
  fill: #404040;
}

.dark .dark\:stroke-popupPrimary {
  stroke: #fbf8f4;
}

.dark .dark\:stroke-darkPrimary {
  stroke: #fff;
}

.dark .dark\:text-darkPrimary {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .dark\:text-primary {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.dark .dark\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.dark .dark\:shadow-darkCard {
  --tw-shadow: 0px 2px 3px 0px rgba(0, 0, 0, .5), 0px 4px 6px 0px rgba(0, 0, 0, .25), 0px 0px 2px 0px rgba(255, 255, 255, .5) inset;
  --tw-shadow-colored: 0px 2px 3px 0px var(--tw-shadow-color), 0px 4px 6px 0px var(--tw-shadow-color), 0px 0px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:hover\:border-purpleLighter:hover {
  --tw-border-opacity: 1;
  border-color: rgba(123, 102, 255, var(--tw-border-opacity));
}

.dark .hover\:dark\:bg-darkPopupPrimary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 248, 244, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-darkBackground:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 64, 64, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-background:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 248, 244, var(--tw-bg-opacity));
}

.dark .hover\:dark\:bg-darkSurface:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 26, 26, var(--tw-bg-opacity));
}

.dark .hover\:dark\:fill-purple:hover {
  fill: #5e00ff;
}

.dark .hover\:dark\:text-primary:hover, .dark .dark\:hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-purpleLighter:hover, .dark .hover\:dark\:text-purpleLighter:hover {
  --tw-text-opacity: 1;
  color: rgba(123, 102, 255, var(--tw-text-opacity));
}

.dark .hover\:dark\:opacity-75:hover {
  opacity: .75;
}

.dark .dark\:hover\:shadow-darkCard:hover {
  --tw-shadow: 0px 2px 3px 0px rgba(0, 0, 0, .5), 0px 4px 6px 0px rgba(0, 0, 0, .25), 0px 0px 2px 0px rgba(255, 255, 255, .5) inset;
  --tw-shadow-colored: 0px 2px 3px 0px var(--tw-shadow-color), 0px 4px 6px 0px var(--tw-shadow-color), 0px 0px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

@media (min-width: 640px) {
  .sm\:top-0 {
    top: 0;
  }

  .sm\:top-16 {
    top: 4rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:ml-16 {
    margin-left: 4rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:flex-auto {
    flex: auto;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 768px) {
  .md\:fixed {
    position: fixed;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-0 {
    height: 0;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:w-\[50vw\] {
    width: 50vw;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:max-w-provenance {
    max-width: 500px;
  }

  .md\:max-w-\[100vw\] {
    max-width: 100vw;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:pl-mainLeft {
    padding-left: 17.25rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pb-2 {
    padding-bottom: .5rem;
  }

  .md\:pb-12 {
    padding-bottom: 3rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:max-w-\[40\%\] {
    max-width: 40%;
  }

  .lg\:flex-\[0\.4\] {
    flex: .4;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pt-7 {
    padding-top: 1.75rem;
  }
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  -webkit-animation: none;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px rgba(0, 0, 0, .08);
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
  animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  height: 34px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 34px;
  z-index: 3;
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid rgba(57, 57, 57, .15);
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, .1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, .2);
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, .5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, .05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 34px;
  text-align: center;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, .9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, .9);
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba(0, 0, 0, .5);
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: menulist;
  -ms-appearance: menulist;
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  height: auto;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: rgba(0, 0, 0, .54);
  text-align: center;
  background: none;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: flex;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #e6e6e6;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e6e6e6;
  border-color: #e6e6e6;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #959ea9;
  border-color: #959ea9;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #569ff7;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border-color: rgba(0, 0, 0, 0);
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, .1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  box-sizing: border-box;
  outline: 0;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #393939;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  width: 2%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/*# sourceMappingURL=index.c4a0b46c.css.map */
